<template>
    <div :class="'bg-' + theme + ' text-' + text_colour" class="mb-2 rounded-lg" data-aos="fade-up">
        <h3 @click.prevent="accordionOpen = !accordionOpen" class="mb-0 cursor-pointer flex items-center justify-between">{{ title }}
            <ChevronUpIcon v-if="accordionOpen" class="inline h-6 w-6"/>
            <ChevronDownIcon v-else class="inline h-6 w-6"/>
        </h3>
        <div class="mt-4" :class="{'hidden' : !accordionOpen}">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    import {ChevronDownIcon, ChevronUpIcon} from '@heroicons/vue/24/solid/index.js'

    export default {
        name: 'Accordion',
        components: {
            ChevronDownIcon, ChevronUpIcon
        },
        props: {
            title: String,
            open: {
                type: Boolean,
                default: false
            },
            theme: {
                type: String,
                default: 'white'
            },
            text_colour: {
                type: String,
                default: 'black'
            }
        },
        data() {
            return {
                accordionOpen: this.open
            }
        }
    }
</script>