<script setup>
    import {ref} from 'vue'
    import {Head} from '@vueuse/head'
    import store from '../store/data.js'

    import DefaultLayout from '../layouts/DefaultLayout.vue'
    import Masthead from '../components/Masthead.vue'
    import ContentSections from '../components/ContentSections.vue'
    import {getPageContentByKey} from '../mixins/helpers.js'

    const page = ref(store.getPage())
    const site = ref(store.getSite())
    const masthead = ref(getPageContentByKey(page.value, 'masthead'))
</script>

<template>
    <Head v-if="masthead">
        <meta property="og:image" :content="masthead.value.image.url">
        <meta name="twitter:card" :content="masthead.value.image.url">
        <meta name="twitter:image:alt" :content="masthead.value.title">
    </Head>
    <DefaultLayout v-if="page">
        <Masthead v-if="masthead" logo="/img/logo-light.svg" theme="light" class="bg-blue" accent="white"
                  :title="masthead.value.title" :image="masthead.value.image" :text="masthead.value.text"
                  :demo_button="masthead.value.demo_button" :button_url="masthead.value.button_url" :button_text="masthead.value.button_text"
        ></Masthead>
        <ContentSections :content="page.content"/>
    </DefaultLayout>
</template>