<script setup>
    import {ref} from 'vue'
    import {Head} from '@vueuse/head'
    import {useRouter, useRoute} from 'vue-router'

    import DefaultLayout from '../layouts/DefaultLayout.vue'
    import Masthead from '../components/Masthead.vue'
    import ContentSections from '../components/ContentSections.vue'
    import RelatedArticlesSection from '../components/sections/RelatedArticlesSection.vue'
    import SecondaryButton from '../components/elements/SecondaryButton.vue'
    import Navigation from '../components/Navigation.vue'
    import {ArrowLeftIcon} from '@heroicons/vue/24/solid/index.js'
    import {articleTheme, formatDate} from '../mixins/helpers.js'
    import {getPageContentByKey, parsePageContent, formatAsset} from '../mixins/helpers.js'
    import store from "../store/data";

    const router = useRouter()
    const route = useRoute()
    const page = ref(store.getPage())
    const site = ref(store.getSite())

    // Bit of a hack
    if(page.value === null || page.value.slug !== router.currentRoute.value.params.slug){
        router.push({path: '/404'})
    }

    const masthead = ref(getPageContentByKey(page.value, 'masthead'))
</script>

<template>
    <Head v-if="masthead">
        <meta property="og:image" :content="masthead.value.image.url">
        <meta name="twitter:card" :content="masthead.value.image.url">
        <meta name="twitter:image:alt" :content="masthead.value.title">
    </Head>
    <DefaultLayout v-if="page">
        <section v-if="masthead" class="relative border" :class="'bg-' + articleTheme(page.page_type.key)">
            <Navigation :theme="'white'" :accent="articleTheme(page.page_type.key).replace('-light', '')"></Navigation>
            <div class="relative z-10 container pb-16 pt-40 lg:pb-32">
                <a :href="$router.resolve({ name: 'articles'}).href" class="items-center hidden lg:flex" title="Back to articles" data-aos="fade-up">
                    <ArrowLeftIcon class="h-[16px] w-[16px] mr-2"/> Back to articles
                </a>
                <div class="text-center max-w-3xl m-auto">
                    <p class="h4 m-8">{{ page.page_type.name }}</p>
                    <h1 class="h2">{{ masthead.value.title }}</h1>
                    <p class="text-md mt-6"><span class="font-medium">Last updated:</span> {{ formatDate(page.updated_at) }}</p>
                </div>
            </div>
            <div v-if="masthead.value.image" class="relative">
                <div class="absolute bg-white h-1/2 -bottom-[1px] left-0 w-full"></div>
                <div class="relative z-10 container">
                    <img :src="formatAsset(masthead.value.image.url, 'width=2400,format=auto')" :alt="page.title" class="w-full h-auto rounded-xl shadow-md" loading="lazy" />
                </div>
            </div>
            <div v-if="masthead.value.text" class="bg-white" :class="'border-b-' + articleTheme(page.page_type.key)">
                <div class="container py-16 lg:py-32 text-center max-w-4xl m-auto">
                    <p class="text-xl">{{ masthead.value.text}}</p>
                </div>
            </div>
        </section>
        <Masthead v-else :class="'bg-' + articleTheme(page.page_type.key)" :accent="articleTheme(page.page_type.key).replace('-light', '')" :title="page.title" :sub-title="page.page_type.name" :text="page.description" />
        <ContentSections :content="page.content"/>
        <RelatedArticlesSection title="Find out more" text="Find inspiration in what our community is creating and more about Sugarcoat." :class="'border border-t-' + articleTheme(page.page_type.key)" />
    </DefaultLayout>
</template>