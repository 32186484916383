<script setup>
    import {ref} from "vue"

    import Feature from '../elements/Feature.vue'
    import PrimaryButton from '../elements/PrimaryButton.vue'
    import {audienceList} from "../../mixins/helpers"

    const audiences = ref(audienceList())
    const activeTab = ref(0)
</script>

<template>
    <section class="relative overflow-hidden bg-red-light-alt">
        <div class="container py-32 lg:pb-64">
            <div class="grid gap-8 grid-cols-12">
                <div class="col-span-12 lg:col-span-5">
                    <h2 class="h4 mb-6">Get started with Sugarcoat</h2>
                    <ul class="flex flex-col gap-2 mb-8">
                        <li v-for="(audience, index) in audiences" :key="index" data-aos="fade-right" :data-aos-delay="index * 50">
                            <div class="transition h2 cursor-pointer" :class="{'text-red' : activeTab === index}" @click.prevent="activeTab = index">{{ audience.title }}</div>
                        </li>
                    </ul>
                </div>
                <div class="col-span-12 lg:col-span-6">
                    <template v-for="(audience, index) in audiences" :key="index">
                        <div v-if="index === activeTab" class="text-center" data-aos="fade-in">
                            <div class="grid gap-8 text-left items-center grid-cols-2">
                                <Feature  v-for="(feature, index) in audience.features" :key="index" theme="red"
                                          :title="feature.title" :description="feature.description"
                                          data-aos="fade-up" :data-aos-delay="index * 50"
                                />
                            </div>
                            <PrimaryButton :href="audience.url" :title="audience.title" class="mt-16">Find out more</PrimaryButton>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div class="transition hidden lg:block absolute bg-cover bg-no-repeat -bottom-[100px] -left-[100px] h-[300px] w-[300px] lg:-bottom-[150px] lg:-left-[0px] lg:h-[600px] lg:w-[600px]"
             style="background-image: url('/img/illustrations/tabbed-audience-section.svg')" data-aos="fade-right"
        ></div>
    </section>
</template>