<template>
    <section :class="'bg-' + theme + ' text-' + text_colour">
        <div class="container py-16 lg:py-32 lg:max-w-5xl">
            <h2 v-if="title" class="mb-16 text-center" data-aos="fade-right">{{ title }}</h2>
            <div data-aos="fade-in">
                <AccordionList :items="items" :theme="theme === 'white' ? 'blue-light-alt' : 'white'"/>
            </div>
        </div>
    </section>
</template>

<script>
    import AccordionList from './../elements/AccordionList.vue'

    export default {
        name: 'FaqsSection',
        components: {AccordionList},
        props: {
            theme: {
                type: String,
                default: 'white'
            },
            text_colour: {
                type: String,
                default: 'black'
            },
            title: String,
            items: Array
        }
    }
</script>