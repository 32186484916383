<script setup>
    import {ref} from "vue"
    import TextFadeUp from '../elements/TextFadeUp.vue'
    import Feature from '../elements/Feature.vue'

    const loaded = ref(false)

    setInterval(() => {
        loaded.value = true
    }, 2000)
</script>
<template>
    <section>
        <div class="container max-w-full py-32">
            <div class="text-center max-w-2xl m-auto mb-16">
                <h2><TextFadeUp text="Join the brands living on the sweeter side" /></h2>
            </div>
            <div class="flex items-center flex-col justify-center lg:flex-row" data-aos="fade-in" data-aos-delay="1000">
                <div class="m-6">
                    <img src="/img/content/clients/cottonprint-logo.png" alt="Cottonprint Logo" class="m-auto max-h-[40px] max-w-none" loading="lazy" />
                </div>
                <div class="m-6">
                    <img src="/img/content/clients/lw-logo.png" alt="Lanchester Wines Logo" class="m-auto max-h-[40px] max-w-none" loading="lazy" />
                </div>
                <div class="m-6">
                    <img src="/img/content/clients/vintrigue-logo.png" alt="Vintrigue Logo" class="m-auto max-h-[50px] max-w-none" loading="lazy" />
                </div>
                <div class="m-6">
                    <img src="/img/content/clients/safe-seat-logo.png" alt="Safe Seat Logo" class="m-auto max-h-[40px] max-w-none" loading="lazy" />
                </div>
                <div class="m-6">
                    <img src="/img/content/clients/mancaves-logo.png" alt="Mancaves Logo" class="m-auto max-h-[40px] max-w-none" loading="lazy" />
                </div>
                <div class="m-6">
                    <img src="/img/content/clients/hamn-logo.png" alt="Hamn Logo" class="m-auto max-h-[40px] max-w-none" loading="lazy" />
                </div>
            </div>
        </div>
    </section>
</template>