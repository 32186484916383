<script setup>
    import {ref} from 'vue'
    import {useRoute, useRouter} from 'vue-router'

    import DefaultLayout from '../layouts/DefaultLayout.vue'
    import Masthead from '../components/Masthead.vue'
    import ContentSections from '../components/ContentSections.vue'
    import {getPageContentByKey, parsePageContent} from '../mixins/helpers.js'
    import store from "../store/data";

    const router = useRouter()
    const route = useRoute()
    const page = ref(store.getPage())
    const site = ref(store.getSite())

    // Bit of a hack
    if(page.value === null || page.value.slug !== router.currentRoute.value.params.slug){
        router.push({path: '/404'})
    }

    const masthead = ref(getPageContentByKey(page.value, 'masthead'))
</script>

<template>
    <DefaultLayout v-if="page">
        <Masthead v-if="masthead" class="bg-blue-light-alt" :title="masthead.value.title" :image="masthead.value.image" :text="masthead.value.text"
                  :demo_button="masthead.value.demo_button" :button_url="masthead.value.button_url" :button_text="masthead.value.button_text"
        ></Masthead>
        <ContentSections :content="page.content"/>
    </DefaultLayout>
</template>