import '../node_modules/aos/dist/aos.css'
import '@formkit/themes/genesis'
// import '@formkit/pro/genesis'
import './style.css'
import App from './App.vue'
import routes from './router/routes.js'
import store from './store/data.js'
import {ViteSSG} from 'vite-ssg'
import AOS from 'aos'
import {ApiWrapper, Config} from '@sugarcoat/js-sdk'
import { plugin, defaultConfig } from '@formkit/vue'
import {getPageContentByKey, parsePageContent} from "./mixins/helpers";

export const createApp = ViteSSG(
    App,
    {
        routes,
        base: import.meta.env.BASE_URL,
        scrollBehavior(to, from, savedPosition) {
            return {x: 0, y: 0}
        }
    },
    ({ app, router, routes, isClient, initialState }) => {
        Config.configure({
            baseUrl: import.meta.env.VITE_APP_API_BASE_URL,
            key: import.meta.env.VITE_APP_API_PUBLIC_KEY
        })

        const sdk = new ApiWrapper(false)
        app.provide('sdk', sdk)
        app.use(plugin, defaultConfig())

        app.config.globalProperties.window = window

        router.beforeEach(async (to) => {
            if (import.meta.env.SSR || import.meta.env.VITE_APP_SPA) {
                const response = await sdk.getChannelBySlug('website')

                if (response.channels.length > 0) {
                    const site = parsePageContent(response.channels[0])
                    store.setSite(site)
                    initialState.site = site
                }

                if (to.meta.load_remote) {
                    const slug = 'slug' in to.params ? to.params.slug : to.meta.slug
                    const response = await sdk.getPageBySlug(slug)

                    if (response.pages.length > 0) {
                        const page = parsePageContent(response.pages[0])
                        store.setPage(page)
                        initialState.page = page
                    }
                    else{
                        router.push({path: '/404'})
                    }
                } else {
                    store.setPage(null)
                    initialState.page = null
                }
            }
        })

        AOS.init({
            duration: 500,
            easing: 'ease-in-out-sine',
            once: true,
            offset: 60
        })
    }
)