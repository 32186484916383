<script setup>
    import { defineProps} from "vue"
    import 'vue3-carousel/dist/carousel.css'
    import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
    import TextFadeUp from '../elements/TextFadeUp.vue'
    const props = defineProps({
        title: {
            type: String,
            default: null
        },
        theme: {
            type: String,
            default: 'white'
        },
        text_colour: {
            type: String,
            default: 'black'
        },
        quotes: Array
    })

    console.log(props)
</script>
<template>
    <section :class="'bg-' + theme + ' text-' + text_colour">
        <div class="container py-32">
            <div v-if="title" class="text-center max-w-2xl m-auto mb-8 lg:mb-16">
                <h2><TextFadeUp :text="title" /></h2>
            </div>
            <div v-if="quotes.length === 1" class="max-w-3xl text-center m-auto" data-aos="fade-in">
                <img v-if="quotes[0].image" :src="quotes[0].image.url" class="rounded-full mx-auto mb-8 shadow-md w-[180px] h-[180px]" :alt="'Image: ' + quotes[0].author" loading="lazy" />
                <p class="font-heading text-[25px] leading-[30px] lg:text-[30px] lg:leading-[40px] mb-8">{{ quotes[0].quote }}</p>
                <p>{{ quotes[0].author }},<br>
                    {{ quotes[0].title }}
                </p>
            </div>
            <carousel v-else-if="quotes.length > 1" :items-to-show="1" :autoplay="5000" :wrap-around="true" :transition="500">
                <slide v-for="(quote, index) in quotes" :key="index">
                    <div class="max-w-3xl text-center m-auto" data-aos="fade-in">
                        <img v-if="quote.image" :src="quote.image.url" class="rounded-full mx-auto mb-8 shadow-md w-[180px] h-[180px] bg-white" :alt="'Image: ' + quote.author" loading="lazy" />
                        <p class="font-heading text-[25px] leading-[30px] lg:text-[30px] lg:leading-[40px] mb-8">{{ quote.quote }}</p>
                        <p>{{ quote.author }},<br>
                           {{ quote.title }}
                        </p>
                    </div>
                </slide>
                <template #addons>
                    <navigation />
                    <pagination />
                </template>
            </carousel>
        </div>
    </section>
</template>

<style>
    :root{
        --vc-pgn-height: 12px;
        --vc-pgn-border-radius: 12px;
    }

    .carousel__pagination{
        margin-top: 3rem;
    }
</style>