<script setup>
    import {useRoute, useRouter} from 'vue-router'

    const router = useRouter()
    const route = useRoute()

    if (route.params.slug === 'rob-mitchell') {
        router.push({path: '/profiles/rob-mitchell/'})
    } else if (route.params.slug === 'jonathan-butler') {
        router.push({path: '/profiles/jonathan-butler'})
    } else {
        window.location.href = '/404'
    }
</script>