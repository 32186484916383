<script setup>
    import {computed, ref} from "vue"

    import PrimaryButton from '../components/elements/PrimaryButton.vue'
    import SecondaryButton from '../components/elements/SecondaryButton.vue'
    import Icon from '../components/elements/Icon.vue'
    import {audienceList} from "../mixins/helpers";

    const audiences = computed(() => { return audienceList()})
    const props = defineProps({
        theme: String,
        accent: String,
        logo: {
            type: String,
            default: '/img/logo.svg'
        },
    })

    const navigationOpen = ref(false)
</script>
<template>
    <header class="absolute z-20 w-full">
        <div class="container py-8 z-50 flex items-center justify-between text-black" :class="{'fixed top-0 w-full lg:relative': navigationOpen, 'text-white' : theme === 'light'}">
            <a href="/" title="Home">
                <img :src="[navigationOpen ? '/img/logo-light.svg': logo]" alt="Sugarcoat Logo" class="h-13" />
            </a>
            <nav class="hidden lg:block">
                <ul class="flex items-center text-lg gap-12">
                    <!--<li><a :href="$router.resolve({ name: 'page', params: {slug: 'why-sugarcoat'}}).href" title="Why Sugarcoat" class="pb-2 hover:border-b-4" :class="'border-b-' + accent">Why Sugarcoat</a></li>-->
                    <li><a :href="$router.resolve({ name: 'page', params: {slug: 'features'}}).href" title="Features" class="pb-2 hover:border-b-4" :class="'border-b-' + accent">Features</a></li>
                    <li><a :href="$router.resolve({ name: 'page', params: {slug: 'built-for'}}).href" title="Built for" class="pb-2 hover:border-b-4" :class="'border-b-' + accent">Built for</a></li>
                    <!--<li class="relative group">
                        <span title="Products" class="cursor-pointer pb-2 hover:border-b-8 border-b-transparent">Built for</span>
                        <div class="shadow-md text-black hidden hover:block group-hover:block absolute bg-white rounded p-8 min-w-[300px] top-[35px] z-50">
                            <ul class="grid gap-8">
                                <li v-for="(audience, index) in audiences" :key="index">
                                    <a :href="audience.url" :title="audience.title" class="flex items-start gap-4">
                                        {{ audience.title }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </li>-->
                    <li><a :href="$router.resolve({ name: 'pricing'}).href" title="Pricing" class="pb-2 hover:border-b-4" :class="'border-b-' + accent">Pricing</a></li>
                    <li><SecondaryButton :theme="theme" :href="$router.resolve({ name: 'sign-up'}).href" title="Sign Up for Free">Sign Up for Free</SecondaryButton></li>
                </ul>
            </nav>
            <div class="block lg:hidden" title="Toggle navigation" @click.prevent="navigationOpen = !navigationOpen">
                <svg v-if="!navigationOpen" xmlns="http://www.w3.org/2000/svg" width="41" height="28" viewBox="0 0 41 28">
                    <g id="Group_2540" data-name="Group 2540" transform="translate(-317 -23)">
                        <rect id="Rectangle_263" data-name="Rectangle 263" width="41" height="4" transform="translate(317 23)" fill="currentColor"/>
                        <rect id="Rectangle_264" data-name="Rectangle 264" width="41" height="4" transform="translate(317 35)" fill="currentColor"/>
                        <rect id="Rectangle_265" data-name="Rectangle 265" width="41" height="4" transform="translate(317 47)" fill="currentColor"/>
                    </g>
                </svg>
                <svg v-if="navigationOpen" class="text-white" xmlns="http://www.w3.org/2000/svg" width="22.466" height="22.467" viewBox="0 0 22.466 22.467">
                    <g id="Group_2580" data-name="Group 2580" transform="translate(-323 -27)">
                        <rect id="Rectangle_263" data-name="Rectangle 263" width="28.948" height="2.824" transform="translate(323 47.47) rotate(-45)" fill="currentColor"/>
                        <rect id="Rectangle_264" data-name="Rectangle 264" width="28.948" height="2.824" transform="translate(324.997 27) rotate(45)" fill="currentColor"/>
                    </g>
                </svg>
            </div>
        </div>
        <div v-if="navigationOpen" class="z-40 bg-black text-white opacity-95 fixed top-0 h-full w-full lg:hidden">
            <div class="container h-full py-8 flex items-center">
                <ul class="flex flex-col text-lg gap-8 w-full text-center">
                    <!--<li><a :href="$router.resolve({ name: 'page', params: {slug: 'why-sugarcoat'}}).href" title="Why Sugarcoat" class="pb-2 hover:border-b-4" :class="'border-b-white'">Why Sugarcoat</a></li>-->
                    <li><a :href="$router.resolve({ name: 'page', params: {slug: 'features'}}).href" title="Features" class="pb-2 hover:border-b-4" :class="'border-b-white'">Features</a></li>
                   <li><a :href="$router.resolve({ name: 'page', params: {slug: 'built-for'}}).href" title="Built for" class="pb-2 hover:border-b-4" :class="'border-b-white'">Built for</a></li>
                    <!--<li v-for="(audience, index) in audiences" :key="index">
                        <a :href="audience.url" :title="audience.title" class="pb-2 hover:border-b-4" :class="'border-b-white'">
                            {{ audience.title }}
                        </a>
                    </li>-->
                    <li><a :href="$router.resolve({ name: 'pricing'}).href" title="Pricing" class="pb-2 hover:border-b-4" :class="'border-b-white'">Pricing</a></li>
                    <li><PrimaryButton theme="light" :href="$router.resolve({ name: 'sign-up'}).href" title="Sign Up for Free" class="block">Sign Up for Free</PrimaryButton></li>
                </ul>
            </div>
        </div>
    </header>
</template>