<script setup>
    import {ref } from 'vue'

    import DefaultLayout from '../layouts/DefaultLayout.vue'
    import Masthead from '../components/Masthead.vue'
    import ContentSections from '../components/ContentSections.vue'
    import PricingSection from '../components/sections/PricingSection.vue'
    import {getPageContentByKey, parsePageContent} from '../mixins/helpers.js'
    import store from "../store/data";
    import {useRoute} from "vue-router";

    const route = useRoute()
    const page = ref(store.getPage())
    const site = ref(store.getSite())
    const masthead = ref(getPageContentByKey(page.value, 'masthead'))
</script>

<template>
    <DefaultLayout v-if="page">
        <Masthead v-if="masthead" class="bg-blue-light-alt" :title="masthead.value.title" :text="masthead.value.text"></Masthead>
        <PricingSection />
        <ContentSections :content="page.content"/>
    </DefaultLayout>
</template>