<script setup>
    import {ref} from "vue"

    import Feature from '../elements/Feature.vue'
    import PrimaryButton from '../elements/PrimaryButton.vue'
    import TextFadeUp from '../elements/TextFadeUp.vue'
    import Tick from '../elements/Tick.vue'
    import {stepsList} from "../../mixins/helpers"

    const steps = ref(stepsList())
</script>

<template>
    <section class="relative overflow-hidden bg-white">
        <div class="container py-16 lg:py-32 lg:max-w-7xl">
            <div class="text-center max-w-2xl m-auto mb-8 lg:mb-16">
                <h2><TextFadeUp text="Ready to Sell in Minutes" /></h2>
            </div>
            <div class="grid gap-4 items-center lg:grid-cols-3">
                <div v-for="(step, index) in steps" :key="index" class="h-full rounded-lg p-8 lg:p-10 text-center lg:aspect-square lg:flex lg:flex-col lg:justify-center" :class="'bg-' + step.theme + '-light-alt'"
                     data-aos="fade-in" :data-aos-delay="index * 100">
                    <span class="rounded-full text-white w-[40px] h-[40px] text-2xl flex justify-center items-center mx-auto mb-6" :class="'bg-' + step.theme">{{ index + 1}}</span>
                    <h3 class="lg:text-3xl mb-4" :class="'text-' + step.theme">{{ step.title }}</h3>
                    <div class="prose text-lg lg:text-xl max-w-xl mx-auto" v-html="step.content"></div>
                </div>
            </div>
            <div class="text-center mt-8 lg:mt-16">
                <PrimaryButton :href="$router.resolve({ name: 'sign-up'}).href" title="Start Selling for Free" data-aos="fade-up" data-aos-delay="500">Start Selling for Free</PrimaryButton>
            </div>
        </div>
    </section>
</template>