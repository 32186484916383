<script setup>
    import {ref, computed} from 'vue'

    import PrimaryButton from '../elements/PrimaryButton.vue'
    import SecondaryButton from '../elements/SecondaryButton.vue'
    import Tick from '../elements/Tick.vue'
    import {benefitList} from '../../mixins/helpers.js'

    const products = computed(() => [
        {
            title: 'sweeter',
            theme: 'blue'
        },
        ... benefitList()
    ])

    const currentProduct = ref(0)

    setInterval(() => {
        if (currentProduct.value >= products.value.length - 1) {
            currentProduct.value = 0
            return
        }

        currentProduct.value++
    }, 2000)
</script>

<template>
    <section class="bg-white">
        <div class="container py-16 lg:py-32">
            <div class="grid gap-8 items-center grid-cols-12 mb-16 lg:mb-32">
                <div class="col-span-12 lg:col-span-6">
                    <h2 class="h1 lg:text-[110px] lg:leading-[100px]">
                        <span class="block" data-aos="fade-up" data-aos-delay="100">Social selling</span>
                        <span class="block" data-aos="fade-up" data-aos-delay="200">made</span>
                        <span class="block" data-aos="fade-up" data-aos-delay="300">
                            <template v-for="(product, index) in products" :key="index">
                                <span v-if="index === currentProduct" class="block" :class="'text-' + product.theme"
                                      data-aos="flip-up">{{ product.title.toLowerCase() }}</span>
                            </template>
                        </span>
                    </h2>
                </div>
                <div class="col-span-12 lg:col-span-5">
                    <div class="prose text-lg mb-8 lg:text-xl" data-aos="fade-in">
                        <p class="text-2xl lg:text-3xl">Start selling on your social platforms in minutes.</p>
                        <ul class="list-none p-0">
                            <li class="pl-0 flex items-center">
                                <Tick class="h-[24px] w-[24px] my-0 mr-2 bg-green-light text-green" />
                                One product catalog for all platforms
                            </li>
                            <li class="pl-0 flex items-center">
                                <Tick class="h-[24px] w-[24px] my-0 mr-2 bg-green-light text-green" />
                                Centralised checkout experience
                            </li>
                            <li class="pl-0 flex items-center">
                                <Tick class="h-[24px] w-[24px] my-0 mr-2 bg-green-light text-green" />
                                Sell anywhere with a link
                            </li>
                            <li class="pl-0 flex items-center">
                                <Tick class="h-[24px] w-[24px] my-0 mr-2 bg-green-light text-green" />
                                No website necessary
                            </li>
                            <li class="pl-0 flex items-center">
                                <Tick class="h-[24px] w-[24px] my-0 mr-2 bg-green-light text-green" />
                                No technical experience required
                            </li>
                        </ul>
                        <p>With flexible payment options for your customers, you can <span class="font-bold">sell more</span> and <span class="font-bold">pay less</span>.</p>
                    </div>
                    <div class="flex gap-2">
                        <PrimaryButton :theme="'dark'" :href="$router.resolve({ name: 'sign-up'}).href" title="Start Selling for Free" data-aos="fade-up" :data-aos-delay="100">Start Selling for Free
                        </PrimaryButton>
                        <SecondaryButton :theme="'dark'" :href="$router.resolve({ name: 'page', params: {slug: 'features'}}).href" title="View Features"
                                         data-aos="fade-up"  :data-aos-delay="150">Find Out How
                        </SecondaryButton>
                    </div>
                </div>
            </div>

            <div class="grid gap-2 lg:gap-4 grid-cols-5 m-auto lg:max-w-5xl">
                <div data-aos="fade-up" data-aos-delay="1000">
                    <img src="/img/icons/facebook.svg" alt="Facebook" class="m-auto w-full h-auto max-w-[50px] lg:max-w-[100px]" loading="lazy" />
                </div>
                <div data-aos="fade-up" data-aos-delay="1100">
                    <img src="/img/icons/instagram.svg" alt="Instagram" class="m-auto w-full h-auto max-w-[50px] lg:max-w-[100px]" loading="lazy" />
                </div>
                <div data-aos="fade-up" data-aos-delay="1200">
                    <img src="/img/icons/google.svg" alt="Google" class="m-auto w-full h-auto max-w-[50px] lg:max-w-[100px]" loading="lazy" />
                </div>
                <div data-aos="fade-up" data-aos-delay="1300">
                    <img src="/img/icons/pintrest.svg" alt="Pintrest" class="m-auto w-full h-auto max-w-[50px] lg:max-w-[100px]" loading="lazy" />
                </div>
                <div data-aos="fade-up" data-aos-delay="1400">
                    <img src="/img/icons/tiktok.svg" alt="Tiktok" class="m-auto w-full h-auto max-w-[50px] lg:max-w-[100px]" loading="lazy" />
                </div>
            </div>
        </div>
    </section>
</template>