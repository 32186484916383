<template>
    <Accordion v-for="(item, index) in items" :key="index" :title="item.title"
               :open="'open' in item ? item.open : false" class="p-6"
               :theme="theme"
               :text-colour="text_colour">
        <div v-html="item.content" class="prose prose-black lg:max-w-4xl"></div>
    </Accordion>
</template>

<script>
    import Accordion from './Accordion.vue'

    export default {
        name: 'AccordionList',
        components: {
            Accordion
        },
        props: {
            items: Array,
            theme: {
                type: String,
                default: 'white'
            },
            text_colour: {
                type: String,
                default: 'black'
            }
        }
    }
</script>