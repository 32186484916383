<template>
    <footer class="bg-black text-white">
        <div class="container py-8 lg:py-16">
            <div class="grid gap-8 grid-cols-2 lg:grid-cols-6">
                <div class="flex flex-col justify-between order-5 lg:order-none col-span-2">
                    <div>
                        <img src="/img/logo-light.svg" alt="Sugarcoat Logo" class="mb-6 max-w-[200px]" loading="lazy"  />
                        <img src="/img/north-of-tyne.png" alt="North of Tyne Logo" class="mb-6 max-w-[200px]" loading="lazy" />
                    </div>
                    <p class="text-sm">Copyright &copy; {{ (new Date()).getFullYear() }} Sugarcoat</p>
                </div>
                <div>
                    <p class="h4 mb-6">Sugarcoat</p>
                    <ul class="flex flex-col gap-2">
                        <!--<li><a>Get Online</a></li>-->
                        <!--<li><a :href="$router.resolve({ name: 'page', params: {slug: 'why-sugarcoat'}}).href" title="Why Sugarcoat">Why Sugarcoat</a></li>-->
                        <li><a :href="$router.resolve({ name: 'page', params: {slug: 'features'}}).href" title="Features">Features</a></li>
                        <li><a :href="$router.resolve({ name: 'pricing'}).href" title="Pricing">Pricing</a></li>
                        <li><a :href="$router.resolve({ name: 'sign-up'}).href" title="Sign Up for Free">Sign Up for Free</a></li>
                    </ul>
                </div>
                <div>
                    <p class="h4 mb-6">Resources</p>
                    <ul class="flex flex-col gap-2">
                        <li><a href="https://app.sugar-coat.io/" title="Admin Login" target="_blank" rel="nofollow">Login</a></li>
                        <li><a href="https://docs.sugar-coat.io/" title="API Documentation" target="_blank" rel="nofollow">API Documentation</a></li>
                        <li><a href="https://stats.uptimerobot.com/v8GY5cEvNv" title="Service Status" target="_blank" rel="nofollow">Service Status</a></li>
                        <li><a href="https://sugar-factory.by-sugarcoat.com" title="Demo Store" target="_blank" rel="nofollow">Demo Store</a></li>
                    </ul>
                </div>
                <div>
                    <p class="h4 mb-6">Company</p>
                    <ul class="flex flex-col gap-2">
                        <!--<li><a :href="$router.resolve({ name: 'page', params: { slug: 'about' }}).href" title="About">About</a></li>-->
                        <li><a :href="$router.resolve({ name: 'articles'}).href" title="Articles">Blog</a></li>
                        <li><a :href="$router.resolve({ name: 'page', params: { slug: 'privacy-policy' }}).href" title="Privacy Policy">Privacy Policy</a></li>
                        <li><a :href="$router.resolve({ name: 'page', params: {slug: 'contact'}}).href" title="Contact">Contact</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>