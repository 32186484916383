import HomePage from '../pages/HomePage.vue'
import AudienceAgenciesPage from '../pages/AudienceAgenciesPage.vue'
import AudienceIndependentSellersPage from '../pages/AudienceIndependentSellersPage.vue'
import AudienceInfluencersPage from '../pages/AudienceInfluencersPage.vue'
import AudienceBusinessesPage from '../pages/AudienceBusinessesPage.vue'
import ProfilePage from '../pages/ProfilePage.vue'
import ArticlePage from '../pages/ArticlePage.vue'
import SignUpPage from '../pages/SignUpPage.vue'
import PricingPage from '../pages/PricingPage.vue'
import GeneralPage from '../pages/GeneralPage.vue'
import QRRedirectPage from '../pages/QRRedirectPage.vue'
import NotFoundPage from '../pages/NotFoundPage.vue'
import ArticleListPage from '../pages/ArticleListPage.vue'
import RegistrationPage from '../pages/RegistrationPage.vue'

export default [
    {
        path: '/',
        name: 'home',
        component: HomePage,
        meta: {
            slug: 'home',
            load_remote: true
        }
    },
    {
        path: '/agencies',
        name: 'agencies',
        component: AudienceAgenciesPage,
        meta: {
            slug: 'agencies',
            load_remote: true
        }
    },
    {
        path: '/independent-sellers',
        name: 'independent-sellers',
        component: AudienceIndependentSellersPage,
        meta: {
            slug: 'independent-sellers',
            load_remote: true
        }
    },
    {
        path: '/influencers',
        name: 'influencers',
        component: AudienceInfluencersPage,
        meta: {
            slug: 'influencers',
            load_remote: true
        }
    },
    {
        path: '/businesses',
        name: 'businesses',
        component: AudienceBusinessesPage,
        meta: {
            slug: 'businesses',
            load_remote: true
        }
    },
    {
        path: '/articles',
        name: 'articles',
        component: ArticleListPage,
        meta: {
            slug: 'articles',
            load_remote: true
        }
    },
    {
        path: '/articles/:slug',
        name: 'article',
        component: ArticlePage,
        meta: {
            load_remote: true
        }
    },
    {
        path: '/profiles/:name',
        name: 'profile',
        component: ProfilePage
    },
    {
        path: '/qr/:slug',
        name: 'qr',
        component: QRRedirectPage
    },
    {
        path: '/pricing',
        name: 'pricing',
        component: PricingPage,
        meta: {
            slug: 'pricing',
            load_remote: true
        }
    },
    {
        path: '/:slug',
        name: 'page',
        component: GeneralPage,
        meta: {
            load_remote: true
        }
    },
    {
        path: '/sign-up',
        name: 'sign-up',
        component: RegistrationPage, // SignUpPage reinstate if sign up issues
        meta: {
            slug: 'sign-up',
            load_remote: true
        }
    },
    {
        path: '/register',
        name: 'register',
        component: RegistrationPage
    },
    {
        path: '/404',
        name: '404',
        component: NotFoundPage
    }
]