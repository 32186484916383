<script setup>
    import {ref, inject} from 'vue'
    import {useRouter, useRoute} from 'vue-router'

    import DefaultLayout from '../layouts/DefaultLayout.vue'
    import Masthead from '../components/Masthead.vue'
    import Button from '../components/elements/Button.vue'
    import ArticleCard from '../components/elements/ArticleCard.vue'
    import {articleTheme, formatDate} from '../mixins/helpers.js'
    import store from "../store/data";

    const router = useRouter()
    const route = useRoute()
    const page = ref(store.getPage())
    const site = ref(store.getSite())

    const sdk = inject('sdk')
    const articles = ref([])
    const filter = ref('type' in route.query ? route.query['type'] : null)

    sdk.getPages(100, [{
        'property': 'page_type_id',
        'value': [4,5,6],
        'operator': 'whereIn'
    }], [{'property': 'created_at', 'direction': 'desc'}]).then(response => {
        articles.value = response.pages
    })
</script>

<template>
    <DefaultLayout v-if="page">
        <Masthead class="bg-blue-light-alt" :title="page.title" :text="page.description" />
        <section class="bg-white">
            <div class="container py-32">
                <div class="flex flex-col items-center mb-8 lg:flex-row">
                    <p class="w-full font-medium text-lg mb-2 text-center lg:text-left lg:mr-4 lg:w-auto">Filter articles by:</p>
                    <Button type='button' @click.prevent="filter = null" class="w-full mb-2 lg:mr-2 lg:w-auto" :class="[ filter === null ? 'border-black' : '']">All</Button>
                    <Button type='button' @click.prevent="filter = 'case-studies'" class="w-full mb-2 lg:mr-2 lg:w-auto" :class="[ filter === 'case-studies'? 'border-black' : '']">Case Studies</Button>
                    <Button type='button' @click.prevent="filter = 'blog'" class="w-full mb-2 lg:mr-2 lg:w-auto" :class="[ filter === 'blog' ? 'border-black' : '']">Blog</Button>
                    <Button type='button' @click.prevent="filter = 'news'" class="w-full mb-2 lg:mr-2 lg:w-auto" :class="[ filter === 'news'? 'border-black' : '']">News</Button>
                </div>
                <div class="grid gap-4 lg:grid-cols-3">
                    <template v-for="(article, index) in articles" :key="article.id">
                        <ArticleCard v-if="!filter || filter === article.page_type.key" :article="article" data-aos="fade-in" :data-aos-delay="index * 50" />
                    </template>
                </div>
            </div>
        </section>
    </DefaultLayout>
</template>