<script setup>
    import { ref} from "vue"
    import PrimaryButton from '../elements/PrimaryButton.vue'

    const submitted = ref(false)
    const errors = ref(false)

    const handleSubmit = (event) => {
        const myForm = event.target;
        const formData = new FormData(myForm)

        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams(formData).toString(),
        })
            .then(() => {
                submitted.value = true
                errors.values = false
            })
            .catch((error) => {
                submitted.value = true
                errors.values = true
                console.debug(error)
            })
    }
</script>

<template>
    <section class="bg-white">
        <div class="container py-16 lg:py-32 lg:max-w-6xl">
            <div class="grid gap-8 items-center grid-cols-12">
                <div class="col-span-12 lg:col-span-5" data-aos="fade-right">
                    <h2 class="mb-8">Want to know more? Talk to us</h2>
                    <p class="text-lg">Leave your information and we'll get back to you to discuss your selling needs.</p>
                </div>
                <div class="col-span-12 lg:col-span-6 lg:col-start-7" data-aos="fade-left">
                   <form v-if="!submitted"
                           name="contact-form"
                           data-netlify="true"
                           netlify-honeypot="bot-field"
                           @submit.prevent="handleSubmit"
                   >
                       <p class="hidden">
                           <label>
                               Don’t fill this out if you’re human: <input name="bot-field" />
                           </label>
                       </p>
                       <div class="mb-4">
                        <input type="text" name="name" placeholder="Full name*" class="w-full" required />
                       </div>
                       <div class="mb-4">
                            <input type="email" name="email" placeholder="Email address*" class="w-full" required />
                       </div>
                       <div class="mb-4">
                           <div class="flex items-center">
                               <label class="font-medium mr-2">I am</label>
                               <select name="audience" required>
                                   <option value="Independent Seller">
                                       an Independent Seller
                                   </option>
                                   <option value="Small Business">
                                       a Small-medium Business
                                   </option>
                                   <option value="Influencer">
                                       an Influencer
                                   </option>
                                   <option value="Agency">
                                       an Agency
                                   </option>
                                   <option value="Other">Other</option>
                               </select>
                           </div>
                       </div>
                       <div class="mb-4">
                           <textarea name="info" placeholder="Tell us more about how we can help you" class="w-full h-40" required></textarea>
                       </div>
                       <input type="hidden" name="form-name" value="contact-form" />
                       <PrimaryButton type="button">Get in Touch</PrimaryButton>
                   </form>
                    <div v-if="submitted">
                        <div v-if="errors">
                            <p class="h3 mb-4 text-red">Whoops, something went wrong.</p>
                            <p class="text-lg">Sorry, we couldn't submit your requests. Please try again.</p>
                        </div>
                        <div v-else>
                            <p class="h3 mb-4 text-green">Thank you.</p>
                            <p class="text-lg">Thank you for your request. Someone will be in touch shortly to discuss your submission.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>