import {reactive} from 'vue'

export default {
    state: reactive({
        token: null,
        page: null,
        site: null
    }),
    setApiToken(token) {
        sessionStorage.setItem('sc_api_token', token.api_token)
        sessionStorage.setItem('sc_api_refresh_token', token.refresh_token)
        this.state.token = token
    },
    getApiToken() {
        return sessionStorage.getItem('sc_api_token');
    },
    setSite(site) {
        this.state.site = site
    },
    getSite() {
        if (import.meta.env.SSR || import.meta.env.VITE_APP_SPA) {
            return this.state.site
        } else {
            const state = JSON.parse(window.__INITIAL_STATE__)
            return state.site
        }
    },
    setPage(page) {
        this.state.page = page
    },
    getPage() {
        if (import.meta.env.SSR || import.meta.env.VITE_APP_SPA) {
            return this.state.page
        } else {
            const state = JSON.parse(window.__INITIAL_STATE__)
            return state.page
        }
    }
}