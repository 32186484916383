<script setup>
    import {ref} from "vue"

    import Feature from '../elements/Feature.vue'
    import PrimaryButton from '../elements/PrimaryButton.vue'
    import TextFadeUp from '../elements/TextFadeUp.vue'
    import Tick from '../elements/Tick.vue'
    import {benefitList} from "../../mixins/helpers"

    const benefits = ref(benefitList())
</script>

<template>
    <section class="relative overflow-hidden bg-white">
        <div class="container py-16 lg:py-32 lg:max-w-7xl">
            <div class="text-center max-w-2xl m-auto mb-8 lg:mb-16">
                <h2><TextFadeUp text="Sugarcoat Benefits" /></h2>
            </div>
            <div class="grid gap-4 items-center lg:grid-cols-3">
                <div v-for="(benefit, index) in benefits" :key="index" class="h-full rounded-lg p-8 lg:p-10" :class="'bg-' + benefit.theme + '-light-alt'"
                     data-aos="fade-in" :data-aos-delay="index * 100">
                    <h3 class="lg:text-3xl mb-6" :class="'text-' + benefit.theme">{{ benefit.title }}</h3>
                    <div class="prose">
                        <ul class="list-none p-0">
                            <li v-for="feature in benefit.features" class="pl-0 flex text-lg leading-snug pb-3">
                                <Tick class="h-[24px] w-[24px] my-0 mr-2" :class="'text-' + benefit.theme + ' bg-' + benefit.theme +  '-light'" />
                                {{ feature.title }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>