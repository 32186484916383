<script setup>
    import { ref } from 'vue'
    import {Head} from '@vueuse/head'

    import DefaultLayout from '../layouts/DefaultLayout.vue'
    import Masthead from '../components/Masthead.vue'
    import ContentSections from '../components/ContentSections.vue'
    import FeatureSection from '../components/sections/FeatureSection.vue'
    import {getPageContentByKey, parsePageContent, audienceList} from '../mixins/helpers.js'
    import store from "../store/data";
    import {useRoute} from "vue-router";

    const audienceData = audienceList().filter((audience) => {
        return audience.title === "Agencies"
    })
    const audience = ref(audienceData[0])

    const route = useRoute()
    const page = ref(store.getPage())
    const site = ref(store.getSite())
    const masthead = ref(getPageContentByKey(page.value, 'masthead'))
</script>

<template>
    <Head v-if="masthead">
        <meta property="og:image" :content="masthead.value.image.url">
        <meta name="twitter:card" :content="masthead.value.image.url">
        <meta name="twitter:image:alt" :content="masthead.value.title">
    </Head>
    <DefaultLayout v-if="page">
        <Masthead v-if="masthead"
                  class="bg-red-light" accent="red"
                  :title="masthead.value.title" :image="masthead.value.image" :text="masthead.value.text"
                  :demo_button="masthead.value.demo_button" :button_url="masthead.value.button_url" :button_text="masthead.value.button_text"
        ></Masthead>
        <FeatureSection accent="red" :title="'Why Sugarcoat is perfect for ' + audience.title" :features="audience.features"></FeatureSection>
        <ContentSections :content="page.content"/>
    </DefaultLayout>
</template>