<script setup>
    import {ref} from 'vue'
    import { useRoute } from 'vue-router'

    import DefaultLayout from '../layouts/DefaultLayout.vue'
    import Navigation from '../components/Navigation.vue'
    import PrimaryButton from '../components/elements/SecondaryButton.vue'
    import SecondaryButton from '../components/elements/SecondaryButton.vue'
    import TextFadeUp from '../components/elements/TextFadeUp.vue'
    import store from "../store/data";
    const route = useRoute()

    const pages = {
        'rob-mitchell': {
            title: 'Rob Mitchell',
            description: 'Profile for Rob Mitchell, Founder and Director at Sugarcoat',
            content: [
                {
                    name: 'Profile',
                    key: 'profile',
                    value: {
                        image: '/img/content/profiles/rob-mitchell.jpg',
                        role: 'Founder, Director',
                        experience: '10+ years of software engineering, freelance and consultancy in the digital marketing and SaaS space.',
                        expertise: 'Ecommerce, partner marketing, software engineering, system architecture, project delivery, AGILE, Scrum, customer service, leadership',
                        bio: 'Rob created the initial version of Sugarcoat in 2016 whilst working for a digital agency and experiencing issues first hand with the limitations of existing ecommerce solutions on the market. Since then, he has worked on building the product and forming the business amongst his full time roles. As of 2021 Rob moved into a full time Director role to pursue his passion for creating a great product and service.',
                        email: 'rob@sugar-coat.io',
                        linked_in: 'https://linkedin.com/in/rob-mitchell-33962a2b'
                    }
                }
            ],
            seo: {
                title: 'Rob Mitchell',
                description: 'Profile for Rob Mitchell, Founder and Director at Sugarcoat',
            }
        },
        'jonathan-butler': {
            title: 'Jonathan "JB" Butler',
            description: 'Profile for Jonathan Butler, Director at Sugarcoat',
            content: [
                {
                    name: 'Profile',
                    key: 'profile',
                    value: {
                        image: '/img/content/profiles/jb.png',
                        role: 'Director',
                        experience: '8+ years of software engineering, product management leadership and customer support for a global SaaS solution.',
                        expertise: 'Product management & leadership, partner marketing, customer relations',
                        bio: 'JB joined Sugarcoat in 2022 when the product was growing and needed additional support on product development and vision for the product. He is responsible for the product vision and overseeing the overall product strategy of Sugarcoat backed by more than 8 years of software engineering experience and 4  years of product management and product leadership.',
                        email: 'jonathan@sugar-coat.io',
                        linked_in: 'https://linkedin.com/in/jonathan-butler-93975465'
                    }
                }
            ],
            seo: {
                title: 'Jonathan "JB" Butler',
                description: 'Profile for Jonathan Butler, Director at Sugarcoat',
            }
        }
    }

    const page = ref(pages[route.params.name])
    const site = ref(store.getSite())
</script>

<template>
    <DefaultLayout v-if="page">
        <section class="bg-blue-light-alt">
            <Navigation :theme="'white'" :accent="'light-blue'"></Navigation>
            <div class="container pb-16 pt-40 lg:pb-32 max-w-6xl">
                <div class="grid grid-cols-12 gap-8 lg:items-center">
                    <div class="col-span-12 lg:order-2 lg:col-span-5" data-aos="fade-left">
                        <img v-if="page.content[0].value.image" :src="page.content[0].value.image" :alt="page.title" class="max-w-full rounded-lg" loading="lazy" />
                    </div>
                    <div class="col-span-12 lg:col-span-7" data-aos="fade-right">
                        <div class="lg:max-w-xl">
                            <h1 class="mb-2">
                                <TextFadeUp :text="page.title"></TextFadeUp>
                            </h1>
                            <h2 class="h3 mb-12">{{ page.content[0].value.role }}</h2>
                            <h3 class="h4 mb-1">Experience</h3>
                            <p class="mb-8">{{ page.content[0].value.experience }}</p>
                            <h3 class="h4 mb-1">Expertise</h3>
                            <p class="mb-8">{{ page.content[0].value.expertise }}</p>
                            <h3 class="h4 mb-1">Bio</h3>
                            <p class="mb-8">{{ page.content[0].value.bio }}</p>
                        </div>
                        <div class="flex gap-2">
                            <PrimaryButton :href="'mailto:' + page.content[0].value.email" :title="'Email ' + page.content[0].value.email">Email</PrimaryButton>
                            <SecondaryButton :href="page.content[0].value.linked_in" :title="'Linked in profile for ' + page.title" target="_blank">Linked In</SecondaryButton>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </DefaultLayout>
</template>