<script setup>
    import {computed, ref} from "vue";
    import {Head} from '@vueuse/head'

    import DefaultLayout from '../layouts/DefaultLayout.vue'
    import Masthead from '../components/Masthead.vue'
    import HtmlSection from '../components/sections/HtmlSection.vue'
    import {audienceList, productList} from "../mixins/helpers";
    import store from "../store/data";
    import {useRoute} from "vue-router";

    const route = useRoute()
    const site = ref(store.getSite())

    const audiences = computed(() => { return audienceList()})
</script>

<template>
    <Head>
        <title>Page not found</title>
        <meta name="description" content="Sorry, the page you were looking for does not exist.">
    </Head>
    <DefaultLayout>
        <Masthead class="bg-blue-light-alt" title="Page Not Found" text="Oops, we couldn't find what you are looking for."></Masthead>
        <HtmlSection>
            <p class="text-lg mb-8">Can't find what you're looking for? Why not try one of the links below:</p>
            <ul class="list-disc flex flex-col gap-2 pl-4">
                <li><a :href="$router.resolve({ name: 'home'}).href" title="Home">Home</a></li>
                <li><a :href="$router.resolve({ name: 'page', params: {slug: 'why-sugarcoat'}}).href" title="Why Sugarcoat">Why Sugarcoat</a></li>
                <li><a :href="$router.resolve({ name: 'page', params: {slug: 'features'}}).href" title="Features">Features</a></li>
                <li><a :href="$router.resolve({ name: 'pricing'}).href" title="Pricing">Pricing</a></li>
                <li v-for="(audience, index) in audiences" :key="index">
                    <a :href="audience.url" :title="audience.title ">{{ audience.title }}</a>
                </li>
                <li><a :href="$router.resolve({ name: 'sign-up'}).href" title="Start Selling for Free">Start Selling for Free</a></li>
            </ul>
        </HtmlSection>
    </DefaultLayout>
</template>