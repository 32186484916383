<template>
    <Head v-if="page">
        <title>{{ page.seo.title }}</title>
        <link rel="canonical" :href="window.location.href"/>
        <meta v-if="page.seo.description" name="description" :content="page.seo.description">
        <meta property="og:title" :content="page.seo.title">
        <meta property="og:type" content="website"/>
        <meta property="og:url" :content="window.location.href">
        <meta property="og:site_name" content="Sugarcoat">
        <meta v-if="page.seo.description" property="og:description" :content="page.seo.description">
    </Head>
    <slot>
        <router-view/>
    </slot>
</template>

<script setup>
    import {ref} from 'vue'
    import {Head} from '@vueuse/head'
    import store from "./store/data";
    const page = ref(store.getPage())
</script>
