<script setup>
    import PrimaryButton from '../elements/PrimaryButton.vue'
    import ArticleCard from '../elements/ArticleCard.vue'
    import {defineProps, inject, ref} from "vue";

    const props = defineProps({
        title: String,
        text: String,
        pageTypes: {
            type: Array,
            default:  [4,5,6]
        }

    })

    const articles = ref([])
    const sdk = inject('sdk')

    sdk.getPages(
        { page: 1, pagination_interval: 3 },
        [{
        'property': 'page_type_id',
        'value': props.pageTypes,
        'operator': 'whereIn'
        }],
        [{'property': 'created_at', 'direction': 'desc'}])
    .then(response => {
        articles.value = response.pages
    })
</script>

<template>
    <section class="bg-white">
        <div class="container py-16 lg:py-32">
            <div class="mb-16">
                <h2 class="mb-4">{{ title}}</h2>
                <div class="flex flex-col justify-between lg:items-end lg:flex-row">
                    <p class="text-xl mb-6 lg:mb-0">{{ text }}</p>
                    <PrimaryButton :href="$router.resolve({ name: 'articles'}).href" title="View all articles" >View all articles</PrimaryButton>
                </div>
            </div>
            <div class="grid gap-4 lg:grid-cols-3">
                <template v-for="(article, index) in articles" :key="article.id">
                    <ArticleCard :article="article" data-aos="fade-in" :data-aos-delay="index * 50" />
                </template>
            </div>
        </div>
    </section>
</template>