<script setup>
    import { ref } from 'vue'

    import PrimaryButton from './elements/PrimaryButton.vue'
    import SecondaryButton from './elements/SecondaryButton.vue'

    const showConsent = ref(!document.cookie.includes('cookie_confirmed='))

    const acceptCookies = (level) => {
        document.cookie = "cookie_confirmed=" + level;
        hideConsent()
    }

    const hideConsent = () => {
        showConsent.value = false
    }
</script>

<template>
    <div v-if="showConsent" class="bg-white shadow-2xl text-black sticky bottom-0 z-50" data-aos="fade-up">
        <div class="container py-2">
            <p class="flex flex-col justify-between lg:flex-row lg:items-center">
                <span class="text-sm">This website uses cookies to make sure you have the best experience.<br />
                    For more information check out our <a :href="$router.resolve({ name: 'page', params: { slug: 'privacy-policy' }}).href" title="Enterprise" class="font-bold">Privacy Policy</a>.
                </span>
                <span class="flex justify-between items-center gap-4 lg:order-2 mt-4 lg:mt-0">
                    <PrimaryButton @click.prevent="acceptCookies('true')" title="Accept all cookies" class="flex-grow text-sm" type="button">Accept all cookies</PrimaryButton>
                </span>
            </p>
        </div>
    </div>
</template>