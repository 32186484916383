<script setup>
    import {ref, computed} from "vue"

    import {productList} from "../../mixins/helpers.js"

    import TextFadeUp from '../elements/TextFadeUp.vue'
    const products = computed(() => { return productList()})
    const activeTab = ref(0)
</script>
<template>
    <section class="transition overflow-hidden" :class="'bg-' + products[activeTab].theme + '-light'">
        <div class="container py-16 lg:py-32">
            <ul class="text-center grid justify-center items-center gap-4 grid-cols-2" :class="'lg:grid-cols-' + products.length">
                <li v-for="(product, index) in products" :key="index" class="h-full" data-aos="fade-up" :data-aos-delay="index * 50">
                    <div class="cursor-pointer h-full flex items-center justify-center rounded-lg p-4 whitespace-pre-line" :class="activeTab === index ? 'bg-black-light text-white' : 'bg-white'" @click="activeTab = index">
                        <span class="lg:max-w-[50%]">{{ product.title }}</span>
                    </div>
                </li>
            </ul>
            <template v-for="(product, index) in products" :key="index">
                <div v-show="activeTab === index" data-aos="fade-in">
                    <div class="grid gap-8 items-center grid-cols-12 pt-16 lg:pt-20">
                        <div class="col-span-12 mb-2 lg:col-span-5 lg:mb:0">
                            <h3 class="h2 mb-6 font-normal"><TextFadeUp :text="product.description" /></h3>
                            <p class="text-lg lg:text-xl" data-aos="fade-in">{{ product.summary }}</p>
                        </div>
                        <div class="relative col-span-12 lg:col-span-5 lg:col-start-7">
                            <img :src="product.image" :alt="product.title" class="w-full rounded-t-lg h-auto m-auto relative z-10" />
                            <div class="absolute bg-cover bg-no-repeat -bottom-[50px] -right-[50px] h-[200px] w-[200px] lg:block"
                                 style="background-image: url('/img/illustrations/content-panel.svg')"
                                 data-aos="fade-in"
                            ></div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </section>
</template>